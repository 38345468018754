* {
    margin: 0;
    padding: 0;
}
ul li{
    list-style: none;
}
.ant-form-inline .ant-form-item {
    margin-bottom: 10px !important;
}

.avatar-uploader > .ant-upload {
    width: 160px;
    height: 160px;
}